import { format as _format, toDate, utcToZonedTime } from 'date-fns-tz'

export function formatDateInTimeZone(date: string, options?: { format?: string, timeZone?: string }): string {
  const format = options?.format || "yyyy-MM-dd'T'HH:mm:ssXXX"
  const timeZone = options?.timeZone || 'America/Chicago'

  const inUtc = toDate(date, { timeZone })
  const inTimeZone = utcToZonedTime(inUtc, timeZone)
  return _format(inTimeZone, format , { timeZone })
}
