import React from 'react'

import {connectHits, HitsProvided} from 'react-instantsearch-core'
import { Highlight } from 'react-instantsearch-dom'
import { JTJEntriesHit } from '../../lib/algolia/model'
import { formatDateInTimeZone } from '../../lib/formatDateInTimeZone'

export function EntryHits(props: HitsProvided<JTJEntriesHit>) {
  return <ol className='ais-Hits-list'>{
    props.hits.map((hit) => {
      return <li key={hit.objectID} className='search__hit ais-Hits-item a-block'>
        <a href={`/${hit.objectID}`}>
          <div className='search__hit-overline'>
            <span className="search__hit-overline-part">
              <Highlight hit={hit} attribute='scripture' />
            </span>
            <span className="search__hit-overline-part">
              <Highlight hit={hit} attribute='writer' />
            </span>
            <span className="search__hit-overline-part">
              {formatDateInTimeZone(hit.date, { format: 'M.d.yyyy' })}
            </span>
          </div>
          <h2 className="search__hit-title">
            <Highlight hit={hit} attribute='title' />
          </h2>
          <div className="search__hit-body">
            <Highlight hit={hit} attribute='reflection_truncated' />
          </div>
        </a>
      </li>
    })}
  </ol>
}

export default connectHits<JTJEntriesHit>(EntryHits)