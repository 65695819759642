import React, { useState } from 'react'

import {Configure, SearchBox, Pagination} from 'react-instantsearch-dom'
import {AlgoliaSearch} from '@watermarkchurch/react-instantsearch-components'
import DropdownMenuRefinement from './search/dropdownMenuRefinement'
import { sortByLabelInScriptureOrder } from './search/labelInScriptureOrder'
import ConnectedEntryHits from './search/entryHits'
import { present } from '../lib/present'
import { SearchState } from 'react-instantsearch-core'

// React 18 omitted "children" from the React.Component prop types
// so we have to add it back in until we update the library for react 18
declare module '@watermarkchurch/react-instantsearch-components' {
  export interface IInstantSearchProps {
    children: React.ReactNode;
  }
}

export interface SearchParams {
  appId: string,
  apiKey: string,
  index: string

  hitsPerPage?: number,
  searchAsYouType?: boolean,
  attributesForFaceting?: Array<string>

  showResultsOnInitialLoad?: boolean
}

/**
 * Each possible value here represents a replica index of the main index.
 * Replicas are primarily used for sorting the results.
 */
type IndexSuffix = 'chron'

export default function Search(params: SearchParams) {
  const attributesForFaceting =
    params.attributesForFaceting ||
    ['curriculum_display_name', 'books', 'tags.title']

  const [showResults, setShowResults] = useState<boolean>(
    params.showResultsOnInitialLoad || false
  )
  const [suffix, setSuffix] = useState<IndexSuffix>()

  const index =
    suffix ? `${params.index}-${suffix}` : params.index

  return <AlgoliaSearch
    widgetName='search'
    apiKey={params.apiKey}
    appId={params.appId}
    indexName={index}
    storeStateInQuery={true}
    onSearchStatechange={React.useCallback((state: SearchState) => {
      // Sort chronologically using replica index if date_year is selected
      if (present(state.menu?.date_year) || present(state.menu?.curriculum_display_name)) {
        setSuffix('chron')
      } else {
        setSuffix(undefined)
      }
      setShowResults(true)
    }, [])}>

    <Configure hitsPerPage={params.hitsPerPage || 20} />

    <SearchBox
      autoFocus={true}
      searchAsYouType={params.searchAsYouType || false}
      submit={<>Search</>} />

    <div className='row'>
      <div className='search__refinements'>
        {
          attributesForFaceting.slice(0, 3).map((attribute) =>
            refinementForAttribute(attribute))
        }
      </div>
    </div>

    <hr />

    {showResults &&
      <ConnectedEntryHits />}

    {showResults && <Pagination
      showFirst={false}
      showLast={false}
      translations={{
        previous: '',
        next: ''
      }}
    />}

  </AlgoliaSearch>
}

function refinementForAttribute(attribute: string): JSX.Element | null {
  switch(attribute) {
  case 'date_year':
    return <DropdownMenuRefinement
      key={attribute}
      attribute='date_year'
      title='Year'
      limit={10}
      showMore={true}
      showMoreLimit={999}
      transformItems={sortbyLabelDesc} />

  case 'books':
    return <DropdownMenuRefinement
      key={attribute}
      attribute='books'
      title='Scripture'
      showMore={false}
      limit={66}
      transformItems={sortByLabelInScriptureOrder} />

  case 'tags.title':
    return <DropdownMenuRefinement
      key={attribute}
      attribute='tags.title'
      title='Topic'
      limit={20}
      showMore={true}
      showMoreLimit={999} />

  case 'curriculum_display_name':
    return <DropdownMenuRefinement
      key={attribute}
      attribute='curriculum_display_name'
      title='Reading Plans'
      limit={20}
      showMore={true}
      showMoreLimit={999} />

  default:
    return null
  }
}

function sortbyLabelDesc<T extends { label: string }>(items: T[]): T[] {
  return items.sort(byLabelDesc)
}

function byLabelDesc(a: {label: string}, b: {label: string}): number {
  return -1 * a.label.localeCompare(b.label)
}
