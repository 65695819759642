import React from 'react'
import 'bootstrap'
import {connectMenu, MenuProvided, MenuExposed} from 'react-instantsearch-core'

interface DropdownMenuRefinementOptions extends MenuProvided, MenuExposed {
  title: string
}

function DropdownMenuRefinement(props: DropdownMenuRefinementOptions) {
  const {
    attribute,
    title,
    items,
    limit,
    showMore,
    showMoreLimit,
    currentRefinement: current,
    refine
  } = props

  // Bootstrap dropdowns need to be initialized on first render
  const ref = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const current = ref.current
    if (!current) { return }

    $(current).dropdown()

    // close any open dropdowns if a click gets this far
    const closer = () => {
      $(current).find('[data-toggle="dropdown"]').dropdown('hide')
    }
    $(document.body).on('click', closer)

    return () => {
      $(current).dropdown('dispose')
      $(document.body).off('click', closer)
    }
  }, [ref.current])

  const toggleDropdown = React.useCallback<React.MouseEventHandler>((evt) => {
    evt.preventDefault()
    evt.stopPropagation()
    $(evt.target).dropdown('toggle')
  }, [ref.current])

  const [expanded, setExpanded] = React.useState(false)
  const loadMore = React.useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setExpanded(true)
  }, [])

  const canToggleShowMore =
    showMore && items.length >= (limit || 10)

  return <div className='dropdown' ref={ref}>
    <button className="btn btn-secondary dropdown-toggle" role="button"
      id={`${attribute}_dropdown`}
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
      onClick={toggleDropdown}
    >
      {current ? current : title}
    </button>

    <div className="dropdown-menu" aria-labelledby={`${attribute}_dropdown`}>
      <div className="dropdown-menu__scroll-container">
        {items
          .slice(0, expanded ? showMoreLimit : limit)
          .map((item) =>
            <button className={`dropdown-item ${item.isRefined ? 'isRefined' : ''}`}
              key={item.value}
              onClick={() => refine(item.value)}>
              {item.label}
            </button>
          )}
      </div>

      {current && <>
        <hr />
        <button className="dropdown-item isRefined" onClick={() => refine(undefined)}>
          Show All
        </button>
      </>}
      {canToggleShowMore && !expanded && !current &&
        <>
          <hr />
          <button className="dropdown-item"
            onClick={loadMore}>
            Load More
          </button>
        </>}
    </div>
  </div>
}

export default connectMenu(DropdownMenuRefinement)
