
export function sortByLabelInScriptureOrder<T extends {label: string}>(items: Array<T>): Array<T> {
  return items.sort(byLabelInScriptureOrder)
}

export function byLabelInScriptureOrder(a: { label: string }, b: { label: string }): number {
  const aIdx = booksInOrder.indexOf(a.label.toLocaleLowerCase())
  const bIdx = booksInOrder.indexOf(b.label.toLocaleLowerCase())
  return aIdx - bIdx
}

const booksInOrder = [
  'genesis',
  'exodus',
  'leviticus',
  'numbers',
  'deuteronomy',
  'joshua',
  'judges',
  'ruth',
  '1 samuel',
  '2 samuel',
  '1 kings',
  '2 kings',
  '1 chronicles',
  '2 chronicles',
  'ezra',
  'nehemiah',
  'esther',
  'job',
  'psalm',
  'psalms',
  'proverbs',
  'ecclesiastes',
  'song of solomon',
  'isaiah',
  'jeremiah',
  'lamentations',
  'ezekiel',
  'daniel',
  'hosea',
  'joel',
  'amos',
  'obadiah',
  'jonah',
  'micah',
  'nahum',
  'habakkuk',
  'zephaniah',
  'haggai',
  'zechariah',
  'malachi',
  'matthew',
  'mark',
  'luke',
  'john',
  'acts',
  'romans',
  '1 corinthians',
  '2 corinthians',
  'galatians',
  'ephesians',
  'philippians',
  'colossians',
  '1 thessalonians',
  '2 thessalonians',
  '1 timothy',
  '2 timothy',
  'titus',
  'philemon',
  'hebrews',
  'james',
  '1 peter',
  '2 peter',
  '1 john',
  '2 john',
  '3 john',
  'jude',
  'revelation',
]
