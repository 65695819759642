/* eslint-disable @typescript-eslint/no-var-requires  */
/* eslint-disable @typescript-eslint/no-explicit-any  */
// Support component names relative to this directory:
import ReactRailsUJS from 'react_ujs'
import path from 'path'

const componentRequireContext = import.meta.globEager('../../javascript/top-level-components/*.tsx') as any

ReactRailsUJS.useContext(componentRequireContext)

Object.keys(componentRequireContext).forEach((fullpath: string) => {
  const component = (componentRequireContext[fullpath]).default
  const componentName = path.basename(fullpath, path.extname(fullpath));

  (window as any)[componentName] = component
})